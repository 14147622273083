﻿import * as jQuery from "jquery";

if (document.getElementById("test-auditif-content")) {
  var questions = [
    "Avez-vous l’impression de faire répéter votre entourage ?",
    "Percevez-vous des problèmes d’articulation chez vos interlocuteurs (par exemple " +
      "avec vos enfants ou petits enfants) ?",
    "Est-ce que parfois vous vous dites : « J’entends mais je ne comprends pas » ?",
    "Avez-vous tendance à monter le son de la télévision ?",
    "Maintenir une conversation dans un milieu bruyant (restaurant, réunion..) est di" +
      "fficile pour vous ?",
    "Avez-vous du mal à localiser certains sons ?",
    "Est-ce que certain sons ou certaines situations (klaxonnes, travaux, cinéma) vou" +
      "s sont dérangeants ?",
    "Avez-vous souvent des acouphènes (sifflement ou bourdonnement) ?",
    "Avez-vous été exposé(e) aux bruits lors de votre activité professionnelle ?",
  ];

  var currentQuestion = 0;
  var yeses = 0;
  var noses = 0;

  function nextQuestion() {
    currentQuestion++;

    jQuery(".js-question").each(function (index, elem) {
      jQuery(elem).toggleClass("current", index === currentQuestion);
    });

    if (currentQuestion >= questions.length) {
      finalize();
    } else {
      jQuery(".question-text").text(questions[currentQuestion]);
    }
  }

  function finalize() {
    jQuery(".test-current").hide();
    jQuery(".resultat").addClass("fini");
    if (yeses >= 3) {
      jQuery(".test-failed").show();
    } else {
      jQuery(".test-success").show();
    }

    jQuery(".find-center").smoothScroll();
  }

  jQuery(".js-reponse-oui").on("click", function () {
    yeses++;
    nextQuestion();
  });

  jQuery(".js-reponse-non").on("click", function () {
    noses++;
    nextQuestion();
  });
}

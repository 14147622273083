﻿import * as $ from "jquery";
import * as jQuery from "jquery";
import { AudioProApi, StatsApi } from "../../generated/api";
import "../global/jquery.sticky.js";
import { formatPhoneNumber } from "../global/phone-number-formatter";

if (window["page"] === "search-results") {
  $(".js-single-search-result").on("mousedown", function (event) {
    const target = $(this).data("target");
    const isButtonClick =
      $(event.target).is("a") ||
      $(event.target).hasClass("btn") ||
      $(event.target).parent().hasClass("btn");
    if (isButtonClick) {
      return;
    }

    if (event.which === 1) {
      document.location.href = target;
    } else if (event.which === 2) {
      // middle click opens in a new tab
      window.open(target, "_blank");
    }
  });

  $("#audika-ad-link").on("click", (event) => {
    new StatsApi().saveStat({
      query: {
        centerId: "audika",
        type: "ad-click",
        location: "search-results",
      },
    });
  });

  new StatsApi().saveStats({
    query: {
      requests: window["centers"].map((center) => ({
        centerId: center._id,
        location: "search",
        type: "view-search",
      })),
    },
  });

  $(".js-phone").click(async function (event) {
    event.preventDefault();

    var id = $(this).data("audioproid");
    var phoneNumberResponse = await new AudioProApi().getPhoneNumber({
      audioProId: id,
    });
    var phoneNumber = formatPhoneNumber(phoneNumberResponse.phoneNumber);

    var enseigne = $(this).data("enseigne");
    var city = $(this).data("city");
    var address = $(this).data("address");
    var postalCode = $(this).data("postalcode");

    jQuery("#phoneNumberModal-" + id + " .modal-phone-number").text(
      phoneNumber
    );
    jQuery("#phoneNumberModal-" + id + " .modal-free-call").text(
      phoneNumber.substring(0, 3) === "0 8"
        ? "(Appel Gratuit)"
        : "(Appel non surtaxé)"
    );
    jQuery("#phoneNumberModal-" + id + " .modal-enseigne").text(enseigne);
    jQuery("#phoneNumberModal-" + id + " .modal-postalcode").text(postalCode);
    jQuery("#phoneNumberModal-" + id + " .modal-city").text(city);
    jQuery("#phoneNumberModal-" + id + " .modal-address").text(address);

    // Only on non mobiles devices
    if ($(document).width() > 576) {
      ($("#phoneNumberModal-" + id) as any).modal({
        backdrop: true,
      });
    } else {
      var $el = $(this).children(".telephone");
      if ($el.data("type") === "block") {
        $(this).replaceWith($el.show().css("display", "block"));
      } else {
        $(this).replaceWith($el.show().css("display", "inline-block"));
      }
    }

    window["ga"]("send", {
      hitType: "event",
      eventCategory: "Encart Centre",
      eventAction: "click",
      eventLabel: "Telephone",
    });

    const centerId = $(this).data("centerid");

    new StatsApi().saveStat({
      query: {
        centerId: centerId,
        location: "search",
        type: "click-phone-number",
      },
    });
  });

  jQuery(".detail-button").on("click", (event) => {
    window["ga"]("send", {
      hitType: "event",
      eventCategory: "Encart Centre",
      eventAction: "click",
      eventLabel: "Voir fiche",
    });
  });
}

var activePins = [];
function setPinActive(marker: google.maps.Marker) {
  disableAllPins();
  activePins.push(marker);
  marker.setIcon({ url: "/assets/images/pin-blue.png" });
}

function setPinInactive(marker: google.maps.Marker) {
  activePins = activePins.filter((i) => i != marker);
  marker.setIcon({ url: "/assets/images/pin-yellow.png" });
}

function disableAllPins() {
  activePins.forEach(setPinInactive);
}

var activeCenters = [];
function setCenterActive(id: string) {
  disableAllCenters();
  activeCenters.push(id);
  $("#audio-" + id).addClass("active");
}

function setCenterInactive(id: string) {
  activeCenters = activeCenters.filter((i) => i != id);
  $("#audio-" + id).removeClass("active");
}

function disableAllCenters() {
  activeCenters.forEach(setCenterInactive);
}

var wnd = window as any;

export const initMapResult = () => {
  if (google && google.maps) {
    var reBounds = new google.maps.LatLngBounds();
    $.each(wnd.centers, function (index, center) {
      reBounds.extend(
        new google.maps.LatLng(
          center.location.coordinates[1],
          center.location.coordinates[0]
        )
      );
    });

    var map = new google.maps.Map(document.getElementById("map"), {
      zoom: 12,
      scrollwheel: false,
      center: reBounds.getCenter(),
    });
    map.fitBounds(reBounds);

    /*     new google.maps.Marker({
      icon: "/assets/images/home_marker.png",
      position: new google.maps.LatLng(wnd.centerLat, wnd.centerLng),
      map: map,
      title: wnd.search,
    }); */

    $.each(wnd.centers, function (index, center) {
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          center.location.coordinates[1],
          center.location.coordinates[0]
        ),
        map: map,
        title: center.enseigne,
        icon: "/assets/images/pin-yellow.png",
      });

      marker.addListener("click", function () {
        console.log("Pin clicked", center);

        setPinActive(marker);
        setCenterActive(center._id);

        var offset = $("#audio-" + center._id).offset();

        $("html, body").animate(
          {
            scrollTop: offset.top - 10,
          },
          500
        );
      });

      $("#audio-" + center._id).on("mouseenter", () => {
        disableAllCenters();
        setPinActive(marker);
      });
      $("#audio-" + center._id).on("mouseleave", () => setPinInactive(marker));
    });

    $(window).on("load", function () {
      var elementBottom =
        $(".search--result")[0].getBoundingClientRect().bottom + window.scrollY;
      var docHeight = $(document).height();

      console.log("height:", docHeight);
      console.log("bottom:", elementBottom);
      var spacing = docHeight - elementBottom;

      console.log("diff:", spacing);

      ($("#map-container") as any).sticky({
        topSpacing: 0,
        bottomSpacing: spacing,
      });
      ($("#map-container") as any).sticky("update");
    });
  }
};

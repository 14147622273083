﻿const wnd = window as any;

import * as jQuery from "jquery";
import { RdvApi } from "../../generated/api";
import { fromPairs } from "../../utils/js-utils";

if (wnd.page === "rdv-page") {
  jQuery(".rdvform").on("submit", function (event) {
    debugger;
    event.preventDefault();
    event.stopPropagation();
    const $this = $(this);
    var formValues = fromPairs(
      $this.serializeArray().map((i) => [i.name, i.value])
    );

    console.log(formValues);

    $this.find(".ask-for-rdv-loader").show();
    $this.find(".ask-for-rdv").hide();
    new RdvApi().globalAskForRdv({ query: formValues }).then(() => {
      console.log("OK!");
    });
    $(".rdv-success").show();
    $(".ask-for-rdv-loader").hide();
    $(".rdvform").hide();

    return false;
  });
}

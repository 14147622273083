export function initHiddenMap() {
  if (document.getElementById('map-for-autocomplete')) {
    //We need a hidden map on the homepage to have the autocomplete work
    var map = new google.maps.Map(document.getElementById('map-for-autocomplete'), { zoom: 12, scrollwheel: false });
  }
}

export function loadParallax() {
  window['$'] = $;
  window['jQuery'] = $;
}

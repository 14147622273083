/**
 * 1001Audios
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as assign from "core-js/library/fn/object/assign";
import * as url from "url";

interface Dictionary<T> {
  [index: string]: T;
}
export interface FetchAPI {
  (url: string, init?: any): Promise<any>;
}

const BASE_PATH = ""; // "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
  url: string;
  options: any;
}

export class BaseAPI {
  basePath: string;
  fetch: FetchAPI;

  constructor(fetch: FetchAPI = window["fetch"], basePath: string = BASE_PATH) {
    this.basePath = basePath;
    this.fetch = fetch;
  }
}

export interface Access {
  id?: number;
  name?: string;
  picture?: string;
}

export interface AddOwnerRequest {
  audioProId?: string;
}

export interface AdminUpdateOfferModel {
  adminCode?: number;
  offers?: Array<OfferInfo>;
  hideExpiration?: boolean;
}

export interface AdminUpdateVisibilityModel {
  adminCode?: number;
  visibilities?: Array<VisibilityInfo>;
}

export interface AudioPro {
  _id?: string;
  enseigne?: string;
  secondaryTitle?: string;
  alternateTitle?: string;
  nomComplementaire?: string;
  adresseComplement?: string;
  nomHebergeur?: string;
  forceGPS?: boolean;
  adresse?: string;
  codePostal?: string;
  ville?: string;
  tel?: string;
  ownerValidated?: boolean;
  userCreated?: boolean;
  createdAt?: Date;
  pricing?: Pricing;
  brands?: Array<Brand>;
  healthcares?: Array<Healthcare>;
  paymentMethods?: Array<Healthcare>;
  accesses?: Array<Access>;
  paymentServices?: Array<Paymentservice>;
  socialSecurityServices?: Array<Socialsecurityservice>;
  providedServiceId?: number;
  owner?: string;
  premiumUntil?: Date;
  updatedAt?: Date;
  logo?: string;
  website?: string;
  websiteLinkTitle?: string;
  descriptifCentre?: string;
  personalizedDescription?: string;
  footerText?: string;
  location?: Location;
  email?: string;
  slug?: string;
  previousSlugs?: Array<string>;
  photo?: string;
  pdftarifs?: string;
  descriptifAcces?: string;
  showRdvEmailMode?: boolean;
  showRdvUrlMode?: boolean;
  rdvEmail?: string;
  secondaryRdvEmails?: Array<string>;
  marketingCode?: string;
  centerCode?: string;
  rdvUrl?: string;
  displaysOrl?: boolean;
  transportModes?: Array<TransportMode>;
  openingDays?: Array<OpeningDay>;
  callCenterDays?: Array<OpeningDay>;
  isPremium?: boolean;
  affected?: boolean;
  lat?: number;
  lng?: number;
  news?: AudioproNews;
  onlineShop?: AudioproOnlineShop;
  displayCallCenterModal?: boolean;
  callCenterCallBackDelay?: string;
  callCenterPopupTextOpen?: string;
  callCenterPopupTextClosed?: string;
  gifUrl?: string;
  displayRdvPopupForExternalLinks?: boolean;
  rdvBoxesCount?: number;
  popupFreeTestEnabled?: boolean;
  popupFreeTestDelay?: number;
}

export interface AudioproNews {
  title?: string;
  description?: string;
  imageUrl?: string;
}

export interface AudioproOnlineShop {
  title?: string;
  description?: string;
  imageUrl?: string;
  link?: string;
}

export interface Brand {
  id?: number;
  name?: string;
  picture?: string;
  isAdminOnly?: boolean;
  sortOrder?: number;
}

export interface GetCurrentTextResponse {
  text?: string;
  couponUrl?: string;
}

export interface GetTrackedCallsResponse {
  trackedCalls?: Array<TrackedCallByCompanyName>;
}

export interface Healthcare {
  id?: number;
  name?: string;
  picture?: string;
}

export interface Location {
  type?: string;
  coordinates?: Array<number>;
}

export interface OfferInfo {
  centerId?: string;
  month?: number;
  year?: number;
  text?: string;
}

export interface Opening {
  start?: number;
  end?: number;
  isRdv?: boolean;
}

export interface OpeningDay {
  open?: boolean;
  openings?: Array<Opening>;
}

export interface Orl {
  _id?: string;
  name?: string;
  address?: string;
  addressComplement?: string;
  postalCode?: string;
  city?: string;
  phoneNumber?: string;
  secondaryTitle?: string;
  activity?: string;
  location?: Location;
  lat?: number;
  lng?: number;
  forceGPS?: boolean;
  doctolibUrl?: string;
  previousSlugs?: Array<string>;
  conventionnementSS?: OrlConventionnement;
  paymentServices?: Array<OrlPaymentservice>;
  accesses?: Array<OrlAccess>;
  specialities?: Array<OrlSpeciality>;
  descriptifAcces?: string;
  transportModes?: Array<OrlTransport>;
  degrees?: Array<OrlDegree>;
}

export interface OrlAccess {
  id?: number;
  name?: string;
  picture?: string;
}

export interface OrlDegree {
  years?: string;
  description?: string;
}

export interface OrlConventionnement {
  id?: number;
  name?: string;
}

export interface OrlPaymentservice {
  id?: number;
  name?: string;
  picture?: string;
}

export interface OrlSpeciality {
  id?: number;
  name?: string;
}

export interface OrlTransport {
  transportMode?: string;
  stop?: string;
  lines?: Array<string>;
}

export interface Paymentservice {
  id?: number;
  name?: string;
  picture?: string;
}

export interface PhoneNumberResponse {
  phoneNumber?: string;
  originalPhoneNumber?: string;
}

export interface Pricing {
  mode?: string;
  class1Description?: string;
  startingFromOnly?: number;
  startingFrom?: number;
  fromDescription?: string;
  fromUptoDescription?: string;
  upTo?: number;
  monthlyPrice?: number;
  months?: number;
  monthlyDescription?: string;
  deviceCount?: number;
  products?: Array<Product>;
  rac0?: boolean;
  additionalSearchText1?: string;
  additionalSearchText2?: string;
}

export interface Product {
  name?: string;
  description?: string;
  price?: number;
  mode?: string;
}

export interface RdvQuery {
  name?: string;
  phoneNumber?: string;
  email?: string;
  message?: string;
  civility?: string;
  bilandAuditifGratuit?: string;
  devisAppareillageGratuit?: string;
  essaiGratuit?: string;
  mode?: string;
  isMobile?: boolean;
}

export interface SetTextsModel {
  descriptions?: Array<string>;
  pricings?: Array<string>;
  footerTexts?: Array<string>;
}

export interface Socialsecurityservice {
  id?: number;
  name?: string;
  picture?: string;
}

export interface StatRequest {
  type?: string;
  location?: string;
  centerId?: string;
}

export interface StatRequests {
  requests?: Array<StatRequest>;
}

export interface TrackedCallByCompanyName {
  _id?: string;
  companyName?: string;
}

export interface TransportMode {
  mode?: string;
  stop?: string;
  lines?: Array<string>;
}

export interface UpdateTrackedCallsRequest {
  trackedCalls?: Array<TrackedCallByCompanyName>;
}

export interface VisibilityInfo {
  centerId?: string;
  month?: number;
  year?: number;
}

/**
 * AdminApi - fetch parameter creator
 */
export const AdminApiFetchParamCreator = {
  /**
   * @param email
   */
  adminLogin(params: { email?: string }, options?: any): FetchArgs {
    const baseUrl = `/admin/api/admin-login`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["email"]) {
      fetchOptions.body = JSON.stringify(params["email"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param email
   */
  adminLogin_1(params: { email?: string }, options?: any): FetchArgs {
    const baseUrl = `/admin/admin-login`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["email"]) {
      fetchOptions.body = JSON.stringify(params["email"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param id
   */
  deleteNotifications(params: { id?: string }, options?: any): FetchArgs {
    const baseUrl = `/admin/api/delete-notification`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["id"]) {
      fetchOptions.body = JSON.stringify(params["id"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param id
   */
  deleteNotifications_2(params: { id?: string }, options?: any): FetchArgs {
    const baseUrl = `/admin/delete-notifications`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["id"]) {
      fetchOptions.body = JSON.stringify(params["id"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   */
  getTrackedCalls(options?: any): FetchArgs {
    const baseUrl = `/api/tracked-calls`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "GET" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param request
   */
  updateTrackedCalls(
    params: { request?: UpdateTrackedCallsRequest },
    options?: any
  ): FetchArgs {
    const baseUrl = `/api/tracked-calls`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["request"]) {
      fetchOptions.body = JSON.stringify(params["request"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * AdminApi - functional programming interface
 */
export const AdminApiFp = {
  /**
   * @param email
   */
  adminLogin(
    params: { email?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = AdminApiFetchParamCreator.adminLogin(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param email
   */
  adminLogin_1(
    params: { email?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = AdminApiFetchParamCreator.adminLogin_1(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param id
   */
  deleteNotifications(
    params: { id?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = AdminApiFetchParamCreator.deleteNotifications(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param id
   */
  deleteNotifications_2(
    params: { id?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = AdminApiFetchParamCreator.deleteNotifications_2(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   */
  getTrackedCalls(
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<GetTrackedCallsResponse> {
    const fetchArgs = AdminApiFetchParamCreator.getTrackedCalls(options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param request
   */
  updateTrackedCalls(
    params: { request?: UpdateTrackedCallsRequest },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = AdminApiFetchParamCreator.updateTrackedCalls(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * AdminApi - object-oriented interface
 */
export class AdminApi extends BaseAPI {
  /**
   * @param email
   */
  adminLogin(params: { email?: string }, options?: any) {
    return AdminApiFp.adminLogin(params, options)(this.fetch, this.basePath);
  }
  /**
   * @param email
   */
  adminLogin_1(params: { email?: string }, options?: any) {
    return AdminApiFp.adminLogin_1(params, options)(this.fetch, this.basePath);
  }
  /**
   * @param id
   */
  deleteNotifications(params: { id?: string }, options?: any) {
    return AdminApiFp.deleteNotifications(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param id
   */
  deleteNotifications_2(params: { id?: string }, options?: any) {
    return AdminApiFp.deleteNotifications_2(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   */
  getTrackedCalls(options?: any) {
    return AdminApiFp.getTrackedCalls(options)(this.fetch, this.basePath);
  }
  /**
   * @param request
   */
  updateTrackedCalls(
    params: { request?: UpdateTrackedCallsRequest },
    options?: any
  ) {
    return AdminApiFp.updateTrackedCalls(params, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * AdminApi - factory interface
 */
export const AdminApiFactory = function (fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * @param email
     */
    adminLogin(params: { email?: string }, options?: any) {
      return AdminApiFp.adminLogin(params, options)(fetch, basePath);
    },
    /**
     * @param email
     */
    adminLogin_1(params: { email?: string }, options?: any) {
      return AdminApiFp.adminLogin_1(params, options)(fetch, basePath);
    },
    /**
     * @param id
     */
    deleteNotifications(params: { id?: string }, options?: any) {
      return AdminApiFp.deleteNotifications(params, options)(fetch, basePath);
    },
    /**
     * @param id
     */
    deleteNotifications_2(params: { id?: string }, options?: any) {
      return AdminApiFp.deleteNotifications_2(params, options)(fetch, basePath);
    },
    /**
     */
    getTrackedCalls(options?: any) {
      return AdminApiFp.getTrackedCalls(options)(fetch, basePath);
    },
    /**
     * @param request
     */
    updateTrackedCalls(
      params: { request?: UpdateTrackedCallsRequest },
      options?: any
    ) {
      return AdminApiFp.updateTrackedCalls(params, options)(fetch, basePath);
    },
  };
};

/**
 * AudioProApi - fetch parameter creator
 */
export const AudioProApiFetchParamCreator = {
  /**
   * @param audioProId
   */
  getPhoneNumber(params: { audioProId: string }, options?: any): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling getPhoneNumber"
      );
    }
    const baseUrl = `/api/phone-number/{audioProId}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * AudioProApi - functional programming interface
 */
export const AudioProApiFp = {
  /**
   * @param audioProId
   */
  getPhoneNumber(
    params: { audioProId: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<PhoneNumberResponse> {
    const fetchArgs = AudioProApiFetchParamCreator.getPhoneNumber(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * AudioProApi - object-oriented interface
 */
export class AudioProApi extends BaseAPI {
  /**
   * @param audioProId
   */
  getPhoneNumber(params: { audioProId: string }, options?: any) {
    return AudioProApiFp.getPhoneNumber(params, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * AudioProApi - factory interface
 */
export const AudioProApiFactory = function (
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     * @param audioProId
     */
    getPhoneNumber(params: { audioProId: string }, options?: any) {
      return AudioProApiFp.getPhoneNumber(params, options)(fetch, basePath);
    },
  };
};

/**
 * CreateCenterApi - fetch parameter creator
 */
export const CreateCenterApiFetchParamCreator = {
  /**
   * @param model
   */
  acceptOwner(params: { model?: AddOwnerRequest }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/accept-owner`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["model"]) {
      fetchOptions.body = JSON.stringify(params["model"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param model
   */
  addOwner(params: { model?: AddOwnerRequest }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/add-owner`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["model"]) {
      fetchOptions.body = JSON.stringify(params["model"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioPro
   */
  createAudioPro(params: { audioPro?: AudioPro }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/create-audiopro`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["audioPro"]) {
      fetchOptions.body = JSON.stringify(params["audioPro"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param model
   */
  declineOwner(params: { model?: AddOwnerRequest }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/decline-owner`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["model"]) {
      fetchOptions.body = JSON.stringify(params["model"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * CreateCenterApi - functional programming interface
 */
export const CreateCenterApiFp = {
  /**
   * @param model
   */
  acceptOwner(
    params: { model?: AddOwnerRequest },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = CreateCenterApiFetchParamCreator.acceptOwner(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param model
   */
  addOwner(
    params: { model?: AddOwnerRequest },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = CreateCenterApiFetchParamCreator.addOwner(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioPro
   */
  createAudioPro(
    params: { audioPro?: AudioPro },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = CreateCenterApiFetchParamCreator.createAudioPro(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param model
   */
  declineOwner(
    params: { model?: AddOwnerRequest },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = CreateCenterApiFetchParamCreator.declineOwner(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * CreateCenterApi - object-oriented interface
 */
export class CreateCenterApi extends BaseAPI {
  /**
   * @param model
   */
  acceptOwner(params: { model?: AddOwnerRequest }, options?: any) {
    return CreateCenterApiFp.acceptOwner(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param model
   */
  addOwner(params: { model?: AddOwnerRequest }, options?: any) {
    return CreateCenterApiFp.addOwner(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioPro
   */
  createAudioPro(params: { audioPro?: AudioPro }, options?: any) {
    return CreateCenterApiFp.createAudioPro(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param model
   */
  declineOwner(params: { model?: AddOwnerRequest }, options?: any) {
    return CreateCenterApiFp.declineOwner(params, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * CreateCenterApi - factory interface
 */
export const CreateCenterApiFactory = function (
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     * @param model
     */
    acceptOwner(params: { model?: AddOwnerRequest }, options?: any) {
      return CreateCenterApiFp.acceptOwner(params, options)(fetch, basePath);
    },
    /**
     * @param model
     */
    addOwner(params: { model?: AddOwnerRequest }, options?: any) {
      return CreateCenterApiFp.addOwner(params, options)(fetch, basePath);
    },
    /**
     * @param audioPro
     */
    createAudioPro(params: { audioPro?: AudioPro }, options?: any) {
      return CreateCenterApiFp.createAudioPro(params, options)(fetch, basePath);
    },
    /**
     * @param model
     */
    declineOwner(params: { model?: AddOwnerRequest }, options?: any) {
      return CreateCenterApiFp.declineOwner(params, options)(fetch, basePath);
    },
  };
};

/**
 * EditCenterApi - fetch parameter creator
 */
export const EditCenterApiFetchParamCreator = {
  /**
   * @param audioProId
   */
  copyCallCenter(params: { audioProId: string }, options?: any): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling copyCallCenter"
      );
    }
    const baseUrl = `/my-account/api/copy-callcenter/{audioProId}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioProId
   * @param copiedFields
   */
  copyFields(
    params: { audioProId: string; copiedFields?: Array<string> },
    options?: any
  ): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling copyFields"
      );
    }
    const baseUrl = `/my-account/api/copy-fields/{audioProId}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["copiedFields"]) {
      fetchOptions.body = JSON.stringify(params["copiedFields"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  copyNonPremiumFields(
    params: { audioProId: string; copiedFields?: Array<string> },
    options?: any
  ): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling copyFields"
      );
    }
    const baseUrl =
      `/my-account/api/copy-non-premium-fields/{audioProId}`.replace(
        `{${"audioProId"}}`,
        `${params["audioProId"]}`
      );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["copiedFields"]) {
      fetchOptions.body = JSON.stringify(params["copiedFields"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioProId
   */
  copyPricing(params: { audioProId: string }, options?: any): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling copyPricing"
      );
    }
    const baseUrl = `/my-account/api/copy-pricing/{audioProId}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioProId
   */
  copyPricingDocument(
    params: { audioProId: string },
    options?: any
  ): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling copyPricingDocument"
      );
    }
    const baseUrl =
      `/my-account/api/copy-pricing-document/{audioProId}`.replace(
        `{${"audioProId"}}`,
        `${params["audioProId"]}`
      );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioPro
   */
  createAudioPro(params: { audioPro?: AudioPro }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/audiopro`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["audioPro"]) {
      fetchOptions.body = JSON.stringify(params["audioPro"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioProId
   * @param model
   */
  setTextsToAllCentersOfThisAccount(
    params: { audioProId: string; model?: SetTextsModel },
    options?: any
  ): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling setTextsToAllCentersOfThisAccount"
      );
    }
    const baseUrl = `/my-account/api/set-texts/{audioProId}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["model"]) {
      fetchOptions.body = JSON.stringify(params["model"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioPro
   */
  updateAudioPro(params: { audioPro?: AudioPro }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/update-audiopro`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["audioPro"]) {
      fetchOptions.body = JSON.stringify(params["audioPro"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * EditCenterApi - functional programming interface
 */
export const EditCenterApiFp = {
  /**
   * @param audioProId
   */
  copyCallCenter(
    params: { audioProId: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.copyCallCenter(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioProId
   * @param copiedFields
   */
  copyFields(
    params: { audioProId: string; copiedFields?: Array<string> },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.copyFields(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  copyNonPremiumFields(
    params: { audioProId: string; copiedFields?: Array<string> },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.copyNonPremiumFields(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioProId
   */
  copyPricing(
    params: { audioProId: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.copyPricing(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioProId
   */
  copyPricingDocument(
    params: { audioProId: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.copyPricingDocument(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioPro
   */
  createAudioPro(
    params: { audioPro?: AudioPro },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.createAudioPro(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioProId
   * @param model
   */
  setTextsToAllCentersOfThisAccount(
    params: { audioProId: string; model?: SetTextsModel },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs =
      EditCenterApiFetchParamCreator.setTextsToAllCentersOfThisAccount(
        params,
        options
      );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioPro
   */
  updateAudioPro(
    params: { audioPro?: AudioPro },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditCenterApiFetchParamCreator.updateAudioPro(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * EditCenterApi - object-oriented interface
 */
export class EditCenterApi extends BaseAPI {
  /**
   * @param audioProId
   */
  copyCallCenter(params: { audioProId: string }, options?: any) {
    return EditCenterApiFp.copyCallCenter(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioProId
   * @param copiedFields
   */
  copyFields(
    params: { audioProId: string; copiedFields?: Array<string> },
    options?: any
  ) {
    return EditCenterApiFp.copyFields(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /** 
    /** 
     * @param audioProId 
     * @param copiedFields 
     */
  copyNonPremiumFields(
    params: { audioProId: string; copiedFields?: Array<string> },
    options?: any
  ) {
    return EditCenterApiFp.copyNonPremiumFields(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioProId
   */
  copyPricing(params: { audioProId: string }, options?: any) {
    return EditCenterApiFp.copyPricing(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioProId
   */
  copyPricingDocument(params: { audioProId: string }, options?: any) {
    return EditCenterApiFp.copyPricingDocument(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioPro
   */
  createAudioPro(params: { audioPro?: AudioPro }, options?: any) {
    return EditCenterApiFp.createAudioPro(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioProId
   * @param model
   */
  setTextsToAllCentersOfThisAccount(
    params: { audioProId: string; model?: SetTextsModel },
    options?: any
  ) {
    return EditCenterApiFp.setTextsToAllCentersOfThisAccount(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param audioPro
   */
  updateAudioPro(params: { audioPro?: AudioPro }, options?: any) {
    return EditCenterApiFp.updateAudioPro(params, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * EditCenterApi - factory interface
 */
export const EditCenterApiFactory = function (
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     * @param audioProId
     */
    copyCallCenter(params: { audioProId: string }, options?: any) {
      return EditCenterApiFp.copyCallCenter(params, options)(fetch, basePath);
    },
    /**
     * @param audioProId
     * @param copiedFields
     */
    copyFields(
      params: { audioProId: string; copiedFields?: Array<string> },
      options?: any
    ) {
      return EditCenterApiFp.copyFields(params, options)(fetch, basePath);
    },
    /**
     * @param audioProId
     */
    copyPricing(params: { audioProId: string }, options?: any) {
      return EditCenterApiFp.copyPricing(params, options)(fetch, basePath);
    },
    /**
     * @param audioProId
     */
    copyPricingDocument(params: { audioProId: string }, options?: any) {
      return EditCenterApiFp.copyPricingDocument(params, options)(
        fetch,
        basePath
      );
    },
    /**
     * @param audioPro
     */
    createAudioPro(params: { audioPro?: AudioPro }, options?: any) {
      return EditCenterApiFp.createAudioPro(params, options)(fetch, basePath);
    },
    /**
     * @param audioProId
     * @param model
     */
    setTextsToAllCentersOfThisAccount(
      params: { audioProId: string; model?: SetTextsModel },
      options?: any
    ) {
      return EditCenterApiFp.setTextsToAllCentersOfThisAccount(params, options)(
        fetch,
        basePath
      );
    },
    /**
     * @param audioPro
     */
    updateAudioPro(params: { audioPro?: AudioPro }, options?: any) {
      return EditCenterApiFp.updateAudioPro(params, options)(fetch, basePath);
    },
  };
};

/**
 * EditOrlApi - fetch parameter creator
 */
export const EditOrlApiFetchParamCreator = {
  /**
   * @param orl
   */
  createOrl(params: { orl?: Orl }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/orl`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["orl"]) {
      fetchOptions.body = JSON.stringify(params["orl"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param orl
   */
  createOrlApi(params: { orl?: Orl }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/create-orl`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["orl"]) {
      fetchOptions.body = JSON.stringify(params["orl"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param orl
   */
  updateOrl(params: { orl?: Orl }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/update-orl`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["orl"]) {
      fetchOptions.body = JSON.stringify(params["orl"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * EditOrlApi - functional programming interface
 */
export const EditOrlApiFp = {
  /**
   * @param orl
   */
  createOrl(
    params: { orl?: Orl },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditOrlApiFetchParamCreator.createOrl(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param orl
   */
  createOrlApi(
    params: { orl?: Orl },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditOrlApiFetchParamCreator.createOrlApi(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param orl
   */
  updateOrl(
    params: { orl?: Orl },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = EditOrlApiFetchParamCreator.updateOrl(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * EditOrlApi - object-oriented interface
 */
export class EditOrlApi extends BaseAPI {
  /**
   * @param orl
   */
  createOrl(params: { orl?: Orl }, options?: any) {
    return EditOrlApiFp.createOrl(params, options)(this.fetch, this.basePath);
  }
  /**
   * @param orl
   */
  createOrlApi(params: { orl?: Orl }, options?: any) {
    return EditOrlApiFp.createOrlApi(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param orl
   */
  updateOrl(params: { orl?: Orl }, options?: any) {
    return EditOrlApiFp.updateOrl(params, options)(this.fetch, this.basePath);
  }
}

/**
 * EditOrlApi - factory interface
 */
export const EditOrlApiFactory = function (
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     * @param orl
     */
    createOrl(params: { orl?: Orl }, options?: any) {
      return EditOrlApiFp.createOrl(params, options)(fetch, basePath);
    },
    /**
     * @param orl
     */
    createOrlApi(params: { orl?: Orl }, options?: any) {
      return EditOrlApiFp.createOrlApi(params, options)(fetch, basePath);
    },
    /**
     * @param orl
     */
    updateOrl(params: { orl?: Orl }, options?: any) {
      return EditOrlApiFp.updateOrl(params, options)(fetch, basePath);
    },
  };
};

/**
 * MyVisibilityApi - fetch parameter creator
 */
export const MyVisibilityApiFetchParamCreator = {
  /**
   * @param code
   * @param centerId
   */
  adminDeleteOffersForCenter(
    params: { code: number; centerId?: string },
    options?: any
  ): FetchArgs {
    // verify required parameter "code" is set
    if (params["code"] == null) {
      throw new Error(
        "Missing required parameter code when calling adminDeleteOffersForCenter"
      );
    }
    const baseUrl = `/my-account/api/my-offer/remove`;
    let urlObj = url.parse(baseUrl, true);
    urlObj.query = assign(
      {},
      urlObj.query,
      {
        centerId: params["centerId"],
        code: params["code"],
      },
      { credentials: "same-origin" }
    );
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param code
   * @param centerId
   */
  adminDeleteVedettesForCenter(
    params: { code: number; centerId?: string },
    options?: any
  ): FetchArgs {
    // verify required parameter "code" is set
    if (params["code"] == null) {
      throw new Error(
        "Missing required parameter code when calling adminDeleteVedettesForCenter"
      );
    }
    const baseUrl = `/my-account/api/my-visibility/remove`;
    let urlObj = url.parse(baseUrl, true);
    urlObj.query = assign(
      {},
      urlObj.query,
      {
        centerId: params["centerId"],
        code: params["code"],
      },
      { credentials: "same-origin" }
    );
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param model
   */
  adminUpdateOffer(
    params: { model?: AdminUpdateOfferModel },
    options?: any
  ): FetchArgs {
    const baseUrl = `/my-account/api/my-offer/update`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["model"]) {
      fetchOptions.body = JSON.stringify(params["model"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param model
   */
  adminUpdateVisibility(
    params: { model?: AdminUpdateVisibilityModel },
    options?: any
  ): FetchArgs {
    const baseUrl = `/my-account/api/my-visibility/update`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["model"]) {
      fetchOptions.body = JSON.stringify(params["model"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   */
  getCurrentOfferText(options?: any): FetchArgs {
    const baseUrl = `/my-account/api/my-offer/get-current-text`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "GET" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param couponUrl
   */
  setCurrenCoupon(params: { couponUrl?: string }, options?: any): FetchArgs {
    const baseUrl = `/my-account/api/my-offer/set-current-coupon`;
    let urlObj = url.parse(baseUrl, true);
    urlObj.query = assign(
      {},
      urlObj.query,
      {
        couponUrl: params["couponUrl"],
      },
      { credentials: "same-origin" }
    );
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param hideExpiration
   * @param newText
   */
  setCurrentOfferText(
    params: { hideExpiration: boolean; newText?: string },
    options?: any
  ): FetchArgs {
    // verify required parameter "hideExpiration" is set
    if (params["hideExpiration"] == null) {
      throw new Error(
        "Missing required parameter hideExpiration when calling setCurrentOfferText"
      );
    }
    const baseUrl = `/my-account/api/my-offer/set-current-text`;
    let urlObj = url.parse(baseUrl, true);
    urlObj.query = assign(
      {},
      urlObj.query,
      {
        newText: params["newText"],
        hideExpiration: params["hideExpiration"],
      },
      { credentials: "same-origin" }
    );
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * MyVisibilityApi - functional programming interface
 */
export const MyVisibilityApiFp = {
  /**
   * @param code
   * @param centerId
   */
  adminDeleteOffersForCenter(
    params: { code: number; centerId?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs =
      MyVisibilityApiFetchParamCreator.adminDeleteOffersForCenter(
        params,
        options
      );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param code
   * @param centerId
   */
  adminDeleteVedettesForCenter(
    params: { code: number; centerId?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs =
      MyVisibilityApiFetchParamCreator.adminDeleteVedettesForCenter(
        params,
        options
      );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param model
   */
  adminUpdateOffer(
    params: { model?: AdminUpdateOfferModel },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = MyVisibilityApiFetchParamCreator.adminUpdateOffer(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param model
   */
  adminUpdateVisibility(
    params: { model?: AdminUpdateVisibilityModel },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = MyVisibilityApiFetchParamCreator.adminUpdateVisibility(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   */
  getCurrentOfferText(
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<GetCurrentTextResponse> {
    const fetchArgs =
      MyVisibilityApiFetchParamCreator.getCurrentOfferText(options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param couponUrl
   */
  setCurrenCoupon(
    params: { couponUrl?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = MyVisibilityApiFetchParamCreator.setCurrenCoupon(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param hideExpiration
   * @param newText
   */
  setCurrentOfferText(
    params: { hideExpiration: boolean; newText?: string },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = MyVisibilityApiFetchParamCreator.setCurrentOfferText(
      params,
      options
    );
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * MyVisibilityApi - object-oriented interface
 */
export class MyVisibilityApi extends BaseAPI {
  /**
   * @param code
   * @param centerId
   */
  adminDeleteOffersForCenter(
    params: { code: number; centerId?: string },
    options?: any
  ) {
    return MyVisibilityApiFp.adminDeleteOffersForCenter(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param code
   * @param centerId
   */
  adminDeleteVedettesForCenter(
    params: { code: number; centerId?: string },
    options?: any
  ) {
    return MyVisibilityApiFp.adminDeleteVedettesForCenter(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param model
   */
  adminUpdateOffer(params: { model?: AdminUpdateOfferModel }, options?: any) {
    return MyVisibilityApiFp.adminUpdateOffer(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param model
   */
  adminUpdateVisibility(
    params: { model?: AdminUpdateVisibilityModel },
    options?: any
  ) {
    return MyVisibilityApiFp.adminUpdateVisibility(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   */
  getCurrentOfferText(options?: any) {
    return MyVisibilityApiFp.getCurrentOfferText(options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param couponUrl
   */
  setCurrenCoupon(params: { couponUrl?: string }, options?: any) {
    return MyVisibilityApiFp.setCurrenCoupon(params, options)(
      this.fetch,
      this.basePath
    );
  }
  /**
   * @param hideExpiration
   * @param newText
   */
  setCurrentOfferText(
    params: { hideExpiration: boolean; newText?: string },
    options?: any
  ) {
    return MyVisibilityApiFp.setCurrentOfferText(params, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * MyVisibilityApi - factory interface
 */
export const MyVisibilityApiFactory = function (
  fetch?: FetchAPI,
  basePath?: string
) {
  return {
    /**
     * @param code
     * @param centerId
     */
    adminDeleteOffersForCenter(
      params: { code: number; centerId?: string },
      options?: any
    ) {
      return MyVisibilityApiFp.adminDeleteOffersForCenter(params, options)(
        fetch,
        basePath
      );
    },
    /**
     * @param code
     * @param centerId
     */
    adminDeleteVedettesForCenter(
      params: { code: number; centerId?: string },
      options?: any
    ) {
      return MyVisibilityApiFp.adminDeleteVedettesForCenter(params, options)(
        fetch,
        basePath
      );
    },
    /**
     * @param model
     */
    adminUpdateOffer(params: { model?: AdminUpdateOfferModel }, options?: any) {
      return MyVisibilityApiFp.adminUpdateOffer(params, options)(
        fetch,
        basePath
      );
    },
    /**
     * @param model
     */
    adminUpdateVisibility(
      params: { model?: AdminUpdateVisibilityModel },
      options?: any
    ) {
      return MyVisibilityApiFp.adminUpdateVisibility(params, options)(
        fetch,
        basePath
      );
    },
    /**
     */
    getCurrentOfferText(options?: any) {
      return MyVisibilityApiFp.getCurrentOfferText(options)(fetch, basePath);
    },
    /**
     * @param couponUrl
     */
    setCurrenCoupon(params: { couponUrl?: string }, options?: any) {
      return MyVisibilityApiFp.setCurrenCoupon(params, options)(
        fetch,
        basePath
      );
    },
    /**
     * @param hideExpiration
     * @param newText
     */
    setCurrentOfferText(
      params: { hideExpiration: boolean; newText?: string },
      options?: any
    ) {
      return MyVisibilityApiFp.setCurrentOfferText(params, options)(
        fetch,
        basePath
      );
    },
  };
};

/**
 * RdvApi - fetch parameter creator
 */
export const RdvApiFetchParamCreator = {
  /**
   * @param orlId
   * @param query
   */
  askForOrlRdv(
    params: { orlId: string; query?: RdvQuery },
    options?: any
  ): FetchArgs {
    // verify required parameter "orlId" is set
    if (params["orlId"] == null) {
      throw new Error(
        "Missing required parameter orlId when calling askForOrlRdv"
      );
    }
    const baseUrl = `/api/ask-for-orl-rdv/{orlId}`.replace(
      `{${"orlId"}}`,
      `${params["orlId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["query"]) {
      fetchOptions.body = JSON.stringify(params["query"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioProId
   * @param query
   */
  askForRdv(
    params: { audioProId: string; query?: RdvQuery },
    options?: any
  ): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling askForRdv"
      );
    }
    const baseUrl = `/api/ask-for-rdv/{audioProId}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["query"]) {
      fetchOptions.body = JSON.stringify(params["query"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  globalAskForRdv(params: { query?: RdvQuery }, options?: any): FetchArgs {
    const baseUrl = `/api/ask-for-rdv-global`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["query"]) {
      fetchOptions.body = JSON.stringify(params["query"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param audioProId
   */
  clickedOnRdvLink(params: { audioProId: string, isMobile?: boolean }, options?: any): FetchArgs {
    // verify required parameter "audioProId" is set
    if (params["audioProId"] == null) {
      throw new Error(
        "Missing required parameter audioProId when calling clickedOnRdvLink"
      );
    }
    const baseUrl = `/api/clicked-on-rdv-link/{audioProId}?isMobile=${params.isMobile}`.replace(
      `{${"audioProId"}}`,
      `${params["audioProId"]}`
    );

    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * RdvApi - functional programming interface
 */
export const RdvApiFp = {
  /**
   * @param orlId
   * @param query
   */
  askForOrlRdv(
    params: { orlId: string; query?: RdvQuery },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = RdvApiFetchParamCreator.askForOrlRdv(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioProId
   * @param query
   */
  askForRdv(
    params: { audioProId: string; query?: RdvQuery },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = RdvApiFetchParamCreator.askForRdv(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  globalAskForRdv(
    params: { query?: RdvQuery },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = RdvApiFetchParamCreator.globalAskForRdv(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param audioProId
   */
  clickedOnRdvLink(
    params: { audioProId: string, isMobile? : boolean },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = RdvApiFetchParamCreator.clickedOnRdvLink(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * RdvApi - object-oriented interface
 */
export class RdvApi extends BaseAPI {
  /**
   * @param orlId
   * @param query
   */
  askForOrlRdv(params: { orlId: string; query?: RdvQuery }, options?: any) {
    return RdvApiFp.askForOrlRdv(params, options)(this.fetch, this.basePath);
  }
  /**
   * @param audioProId
   * @param query
   */
  askForRdv(params: { audioProId: string; query?: RdvQuery }, options?: any) {
    return RdvApiFp.askForRdv(params, options)(this.fetch, this.basePath);
  }
  globalAskForRdv(params: { query?: RdvQuery }, options?: any) {
    return RdvApiFp.globalAskForRdv(params, options)(this.fetch, this.basePath);
  }
  /**
   * @param audioProId
   */
  clickedOnRdvLink(params: { audioProId: string, isMobile?: boolean }, options?: any) {
    return RdvApiFp.clickedOnRdvLink(params, options)(
      this.fetch,
      this.basePath
    );
  }
}

/**
 * RdvApi - factory interface
 */
export const RdvApiFactory = function (fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * @param orlId
     * @param query
     */
    askForOrlRdv(params: { orlId: string; query?: RdvQuery }, options?: any) {
      return RdvApiFp.askForOrlRdv(params, options)(fetch, basePath);
    },
    /**
     * @param audioProId
     * @param query
     */
    askForRdv(params: { audioProId: string; query?: RdvQuery }, options?: any) {
      return RdvApiFp.askForRdv(params, options)(fetch, basePath);
    },
    /**
     * @param audioProId
     */
    clickedOnRdvLink(params: { audioProId: string }, options?: any) {
      return RdvApiFp.clickedOnRdvLink(params, options)(fetch, basePath);
    },
  };
};

/**
 * StatsApi - fetch parameter creator
 */
export const StatsApiFetchParamCreator = {
  /**
   * @param query
   */
  saveStat(params: { query?: StatRequest }, options?: any): FetchArgs {
    const baseUrl = `/api/stat`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["query"]) {
      fetchOptions.body = JSON.stringify(params["query"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
  /**
   * @param query
   */
  saveStats(params: { query?: StatRequests }, options?: any): FetchArgs {
    const baseUrl = `/api/stats`;
    let urlObj = url.parse(baseUrl, true);
    let fetchOptions: RequestInit = assign({}, { method: "POST" }, options, {
      credentials: "same-origin",
    });

    let contentTypeHeader: Dictionary<string> = {};
    contentTypeHeader = { "Content-Type": "application/json" };
    if (params["query"]) {
      fetchOptions.body = JSON.stringify(params["query"] || {});
    }
    if (contentTypeHeader) {
      fetchOptions.headers = contentTypeHeader as any;
    }
    return {
      url: url.format(urlObj),
      options: fetchOptions,
    };
  },
};

/**
 * StatsApi - functional programming interface
 */
export const StatsApiFp = {
  /**
   * @param query
   */
  saveStat(
    params: { query?: StatRequest },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = StatsApiFetchParamCreator.saveStat(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
  /**
   * @param query
   */
  saveStats(
    params: { query?: StatRequests },
    options?: any
  ): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
    const fetchArgs = StatsApiFetchParamCreator.saveStats(params, options);
    return (
      fetch: FetchAPI = window["fetch"],
      basePath: string = BASE_PATH
    ) => {
      return fetch(basePath + fetchArgs.url, fetchArgs.options).then(
        (response) => {
          if (response.status >= 200 && response.status < 300) {
            return response;
          } else {
            throw response;
          }
        }
      );
    };
  },
};

/**
 * StatsApi - object-oriented interface
 */
export class StatsApi extends BaseAPI {
  /**
   * @param query
   */
  saveStat(params: { query?: StatRequest }, options?: any) {
    return StatsApiFp.saveStat(params, options)(this.fetch, this.basePath);
  }
  /**
   * @param query
   */
  saveStats(params: { query?: StatRequests }, options?: any) {
    return StatsApiFp.saveStats(params, options)(this.fetch, this.basePath);
  }
}

/**
 * StatsApi - factory interface
 */
export const StatsApiFactory = function (fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     * @param query
     */
    saveStat(params: { query?: StatRequest }, options?: any) {
      return StatsApiFp.saveStat(params, options)(fetch, basePath);
    },
    /**
     * @param query
     */
    saveStats(params: { query?: StatRequests }, options?: any) {
      return StatsApiFp.saveStats(params, options)(fetch, basePath);
    },
  };
};

export function formatPhoneNumber(phone: string) {
  if (window['countryCode'] === 'CH') {
    if (phone.trim().substring(0, 1) !== '0') {
      phone = '0' + phone;
    }
    return phone
      .trim()
      .replace(/ /g, '')
      .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')
      .trim();
  } else {
    if (phone.replace(/ /g, '').substring(0, 2) === '08') {
      return phone
        .trim()
        .replace(/ /g, '')
        .replace(/0(\d{3})(\d{3})(\d{3})/, '0 $1 $2 $3')
        .trim();
    } else {
      return phone
        .trim()
        .replace(/ /g, '')
        .replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
        .trim();
    }
  }
}

const wnd = window as any;

import * as jQuery from "jquery";
import { AudioProApi, RdvApi, StatsApi } from "../../generated/api";
import { fromPairs } from "../../utils/js-utils";
import { formatPhoneNumber } from "../global/phone-number-formatter";

export function initOrlDetail() {
  if (wnd.page === "orl-detail") {
    $(".js-rdv-url-link").on("click", function (event) {
      const form = $(this).parents("form");
      form.find("button[type=submit]").click();

      if (!(form[0] as HTMLFormElement).checkValidity()) {
        event.preventDefault();
      }
    });

    if (document.getElementById("map")) {
      var myLatLng = {
        lat: wnd.audiopro.location.coordinates[1],
        lng: wnd.audiopro.location.coordinates[0],
      };

      var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 17,
        center: myLatLng,
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: wnd.audiopro.enseigne,
      });
      marker.setIcon({ url: "/assets/images/pin-blue.png" });
    }

    $(".rdv-button").on("click", function () {
      window["ga"]("send", {
        hitType: "event",
        eventCategory: "Fiche Centre",
        eventAction: "click",
        eventLabel: "Demande de RDV",
      });
      new StatsApi().saveStat({
        query: {
          centerId: window["audiopro"]._id,
          location: "detail",
          type: "click-rdv",
        },
      });
    });

    jQuery(".rdvform").on("submit", function (event) {
      const orlid = $(this).data("orlid");
      const mode = $(this).data("mode");
      event.preventDefault();
      event.stopPropagation();
      const $this = $(this);
      var formValues = fromPairs(
        $this.serializeArray().map((i) => [i.name, i.value])
      );

      console.log(formValues);

      formValues = { ...formValues, mode: mode };

      $this.find(".ask-for-rdv-loader").show();
      $this.find(".ask-for-rdv").hide();
      new RdvApi()
        .askForOrlRdv({ orlId: orlid, query: formValues })
        .then(() => {});
      console.log("OK!");
      $this.find(".rdv-success").show();
      $this.find(".ask-for-rdv-loader").hide();
      $this.find("#close").show();
      $this.find(".rdv-inputs").hide();

      return false;
    });

    $(".rdv-link-callcenter").on("click", function (event) {
      event.preventDefault();
      const id = $(this).data("audioproid");

      ($("#rdvLinkModal-" + id) as any).modal({
        backdrop: true,
      });

      if ((window as any).page === "search-results") {
        $("[name=isSearch]").val("true");
      }
    });

    jQuery(".phone-button").on("click", async function (event) {
      var phoneNumber = $(this).data("phonenumber");
      var enseigne = $(this).data("enseigne");
      var city = $(this).data("city");
      var postalcode = $(this).data("postalcode");
      var address = $(this).data("address");

      var modalId =
        $(this).data("type") === "orl"
          ? "#orlPhoneNumberModal"
          : "#phoneNumberModal-" + $(this).data("audioproid");

      if ($(this).data("type") === "audiopro") {
        var id = $(this).data("audioproid");
        var phoneNumberResponse = await new AudioProApi().getPhoneNumber({
          audioProId: id,
        });
        phoneNumber = formatPhoneNumber(phoneNumberResponse.phoneNumber);
      }

      jQuery(modalId + " .modal-phone-number").text(phoneNumber);
      jQuery(modalId + " .modal-free-call").text(
        phoneNumber.substring(0, 3) === "0 8"
          ? "(Appel Gratuit)"
          : "(Appel non surtaxé)"
      );
      jQuery(modalId + " .modal-enseigne").text(enseigne);
      jQuery(modalId + " .modal-city").text(city);
      jQuery(modalId + " .modal-postalcode").text(postalcode);
      jQuery(modalId + " .modal-address").text(address);

      if ($(this).attr("href") === "#") {
        event.preventDefault();

        // Only on non mobiles devices
        if ($(document).width() > 576) {
          ($(modalId) as any).modal({
            backdrop: true,
          });
        } else {
          var $el = $(this).children(".telephone");
          if ($el.length) {
            if ($el.data("type") === "block") {
              $(this).replaceWith($el.show().css("display", "block"));
            } else {
              $(this).replaceWith($el.show().css("display", "inline-block"));
            }
          }
        }
      }
      window["ga"]("send", {
        hitType: "event",
        eventCategory: "ORL",
        eventAction: "click",
        eventLabel: "Telephone",
      });
    });

    jQuery("#orlRdvform").on("submit", (event) => {
      event.preventDefault();
      event.stopPropagation();
      var formValues = fromPairs(
        jQuery("#orlRdvform")
          .serializeArray()
          .map((i) => [i.name, i.value])
      );

      jQuery("#loader").show();
      jQuery("#ask-for-rdv").hide();
      new RdvApi()
        .askForOrlRdv({ orlId: wnd.audiopro._id, query: formValues })
        .then(() => {
          console.log("OK!");
          jQuery("#rdv-success").show();
          jQuery("#loader").hide();
          jQuery("#close").show();
          jQuery("#rdv-inputs").hide();
        });

      return false;
    });
  }

  const scrollDiv = document.createElement("div");
  scrollDiv.className = "modal-scrollbar-measure";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  // Compensate the navbar
  $("#phoneNumberModal").on("show.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", scrollbarWidth);
  });

  $("#phoneNumberModal").on("hidden.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", 0);
  });

  // Compensate the navbar
  $("#orlPhoneNumberModal").on("show.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", scrollbarWidth);
  });

  $("#orlPhoneNumberModal").on("hidden.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", 0);
  });
}

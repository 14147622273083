import * as jQuery from "jquery";
import { StatsApi } from "../generated";

jQuery(".js-save-redirection-stat").on("click", function () {
  const $el = jQuery(this);
  const centerId = $el.data("centerid");

  new StatsApi().saveStat({
    query: {
      centerId: centerId,
      location: document.location.href,
      type: "redirect",
    },
  });
});

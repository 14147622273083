﻿setTimeout(function () {
  function loadGoogleApis() {
    var windowWidth = window.screen.width < window.outerWidth ? window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;

    if(mobile){
      $("<script/>")
        .attr({
          src: "https://maps.googleapis.com/maps/api/js?key="+window.mapsApiKey+"&libraries=places&language=fr&callback=googleMapsLoaded",
        })
        .appendTo("body");
    }else{
      $("<script/>")
        .attr({
          src: "https://maps.googleapis.com/maps/api/js?key="+window.mapsApiKey+"&libraries=geometry,places&language=fr&callback=googleMapsLoaded",
        })
        .appendTo("body");
    }

    $("<script/>")
      .attr({
        src: "https://www.google.com/jsapi",
      })
      .appendTo("body");
  }

  loadGoogleApis();
}, 1);

﻿import * as $ from 'jquery';

let loadingPlacePromise = null;
let placeLoaded = $('input[name=autofilled]').val() === 'true';

export function initAutoComplete() {
  var domElem = document.getElementById('address-input') as HTMLInputElement;
  if(!domElem){
    return;
  }
  
  function pacSelectFirst(input) {
    // store the original event binding function
    var _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;

    function addEventListenerWrapper(type, listener) {
      // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
      // and then trigger the original listener.
      if (type === 'keydown') {
        var orig_listener = listener;
        listener = function(event) {
          var suggestion_selected = $('.pac-item-selected').length > 0;
          if (event.which === 13 && !suggestion_selected) {
            var simulated_downarrow = $.Event('keydown', {
              keyCode: 40,
              which: 40,
            });
            orig_listener.apply(input, [simulated_downarrow]);
          }

          orig_listener.apply(input, [event]);
        };
      }
      if (type === 'blur') {
        var orig_listener = listener;
        listener = function(event) {
          var suggestion_selected = $('.pac-item-selected').length > 0;
          if (!suggestion_selected) {
            var firstResult = $('.pac-container .pac-item:first .pac-item-query').text();
            // if the results are not loaded yet:
            firstResult = firstResult || ($(input).val() as string);
            $(input).val(firstResult);
            loadingPlacePromise = fillPlaceDataFromName(firstResult);
            loadingPlacePromise
              .then(() => {
                loadingPlacePromise = null;
              })
              .catch(err => {
                console.log(err);
                loadingPlacePromise = null;
              });
          }

          orig_listener.apply(input, [event]);
        };
      }

      _addEventListener.apply(input, [type, listener]);
    }

    input.addEventListener = addEventListenerWrapper;
    input.attachEvent = addEventListenerWrapper;
  }


  pacSelectFirst(domElem);

  function fillPlaceDataFromName(name) {
    return new Promise((resolve, reject) => {
      var autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: name,
          offset: name.length,
          componentRestrictions: { country: window.countryCode },
        },
        function(list, status) {
          if (list == null || list.length == 0) {
            reject();
          } else {
            var placeService = new google.maps.places.PlacesService(<any>document.getElementById('map-for-autocomplete'));
            placeService.getDetails(<any>list[0], function(result, status) {
              if (status !== google.maps.places.PlacesServiceStatus.OK) {
                console.error(status);
                reject();
                return;
              }
              fillAutocompleteData(result, true);
              resolve(null);
            });
          }
        }
      );
    });
  }

  $('#search-button').on('click', function(event) {
    if (loadingPlacePromise) {
      loadingPlacePromise.then(() => {
        $('#search-button').click();
      });
      event.preventDefault();
    } else {
      if (!placeLoaded) {
        // $('#address-input').css('border', '1px solid #e35454');
        event.preventDefault();
      }
    }
  });

  function startGeolocation() {
    $('.fa-location-arrow')
      .removeClass('fa-exclamation')
      .addClass('fa-spin')
      .parent()
      .addClass('disabled')
      .attr('disabled', 'disabled');
  }

  function stopGeolocation() {
    $('.fa-location-arrow')
      .removeClass('fa-spin')
      .removeClass('fa-location-arrow')
      .addClass('fa-exclamation-triangle');
  }

  $('#locate-me-button').on('click', function() {
    startGeolocation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: pos }, function(results, status) {
            if (status.toString() === 'OK') {
              if (results[0]) {
                $('#address-input').val(results[0].formatted_address);
                fillAutocompleteData(results[0]);
              } else {
                console.log('No results found');
                stopGeolocation();
              }
            } else {
              console.log('Geocoder failed due to: ' + status);
              stopGeolocation();
            }
          });
        },
        function(err) {
          console.log(err);
          stopGeolocation();
        }
      );
    } else {
      // Browser doesn't support Geolocation
      stopGeolocation();
    }
  });

  // $('.audiopro-type-selector').on('change', event=>{
  //   var newValue = $(event.target).val();
  //   if(newValue === 'audiopro'){
  //     $('form.search').attr('action','/city/search');
  //   }else if(newValue === 'orl'){
  //     $('form.search').attr('action','/orl-oto-rhino-laryngologie/city/search');
  //   }
  // });

  $('#address-input').on('keypress', function(event) {});

  var autocomplete = new google.maps.places.Autocomplete(domElem, {
    types: ['geocode'],
    componentRestrictions: { country: window.countryCode },
  });
  var placeSelected = false;

  // When the user selects an address from the dropdown, populate the address
  // fields in the form.
  google.maps.event.addDomListener(domElem, 'keydown', function(e) {
    if (e.keyCode === 13 && !placeSelected) {
      e.preventDefault();
    }
  });

  autocomplete.addListener('place_changed', function() {
    placeSelected = true;

    var gPlace = autocomplete.getPlace();
    if (gPlace) {
      fillAutocompleteData(gPlace);
      return;
    } else {
      return;
    }
  });

}

function fillAutocompleteData(gPlace, doNotSubmit = false) {
  var EARTH_RADIUS = 6371;

  var n, radius;
  if (gPlace.geometry) {
    var form = $('#search-button').parents('form');
    if (gPlace.geometry.viewport) {
      $('.auto-filled').remove();
      var center = gPlace.geometry.viewport.getCenter();
      var northEast = gPlace.geometry.viewport.getNorthEast();
      var latCenter = center.lat() / 57.2958;
      var lngCenter = center.lng() / 57.2958;
      var latNorthEast = northEast.lat() / 57.2958;
      var lngNorthEast = northEast.lng() / 57.2958;
      radius =
        EARTH_RADIUS *
        Math.acos(
          Math.sin(latCenter) * Math.sin(latNorthEast) + Math.cos(latCenter) * Math.cos(latNorthEast) * Math.cos(lngNorthEast - lngCenter)
        );
      form.append(
        $('<input type="hidden" data-address-component="1" />')
          .val(radius)
          .attr('name', 'radius')
      );
    }

    placeLoaded = true;
    form.find('input[name=place_id]').val(gPlace.place_id);
    form.find('input[name=geometry]').val(JSON.stringify(gPlace.geometry));
    form.find('input[name=latitude]').val(gPlace.geometry.location.lat());
    form.find('input[name=longitude]').val(gPlace.geometry.location.lng());
    form.find('input[data-address-component]').remove();

    for (var p = gPlace.address_components, m = 0; m < p.length; m += 1) {
      var h = p[m];
      n = h.types[0];
      var f = 'country' === n ? h.short_name : h.long_name;
      form.append(
        $('<input type="hidden" data-address-component="1" />')
          .val(f)
          .attr('name', n)
      );
    }

    form.append(
      $('<input type="hidden" data-address-component="1" />')
        .val(gPlace.name || gPlace.formatted_address)
        .attr('name', 'name')
    );
    form.append(
      $('<input type="hidden" data-address-component="1" />')
        .val(gPlace.types[0])
        .attr('name', 'type')
    );
    form.append(
      $('<input type="hidden" data-address-component="1" />')
        .val(gPlace.types)
        .attr('name', 'types')
    );
    form.append(
      $('<input type="hidden" data-address-component="1" />')
        .val(radius)
        .attr('name', 'radius')
    );

    if (!doNotSubmit) {
      form.submit();
    }
  }
}

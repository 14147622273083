import * as $ from "jquery";
import "jquery-smooth-scroll";
import "./front/blog/blog";
import { initAudioProDetail, initRdv } from "./front/detail/audiopro-detail";
import "./front/global/lazy-image-loading";
import { initHiddenMap, loadParallax } from "./front/home/home";
import "./front/home/test";
import { initOrlDetail } from "./front/orl/orl-detail";
import { initChuDetail } from "./front/chu/chu-detail";
import "./front/rdv-page/rdv-page";
  import { initAutoComplete } from "./front/search/autocomplete";
import { initMapResult } from "./front/search/searchresults";
import "./front/stats";
window["jQuery"] = $;
window["$"] = $;
require("bootstrap/js/src/collapse");
require("bootstrap/js/src/dropdown");
require("bootstrap/js/src/carousel");
require("bootstrap/js/src/modal");
require("./front/global/material-scrolltop");

$('[data-spy="afaaafix"]').on("affixed.bs.affix", function () {
  $(".affix").css(
    "width",
    Number($(".affix").parent().css("width").replace("px", "")) - 30
  );
});

function onScroll() {
  var scroll = $(window).scrollTop();
  if (scroll >= 14) {
    $(".header").addClass("scrolled");
  } else {
    $(".header").removeClass("scrolled");
  }
}

onScroll();
$(window).scroll(onScroll);

(jQuery("body") as any).materialScrollTop();

($("a.menulink") as any).smoothScroll();

($("a.menulink") as any).on("click", () => {
  ($("#navbarNav") as any).collapse("hide");
});

($("a.find-center") as any).smoothScroll();

window["googleMapsLoaded"] = function () {
  if (window["page"] === "search-results") {
    initMapResult();
  }

  if (
    window["page"] === "home" ||
    window["page"] === "search-results" ||
    window["page"] === "my-account-search" ||
    window["page"] === "audiopro-detail" ||
    window["page"] === "orl-detail"
  ) {
    initAutoComplete();
    initHiddenMap();
  }

  initAudioProDetail();
  initOrlDetail();
  initChuDetail();
};

initRdv();

if (window["page"] === "home") {
  loadParallax();
}

require("./front/global/libloader");

($(".dropdown-toggle") as any).dropdown();

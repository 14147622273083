import * as jQuery from "jquery";

if (jQuery(document.body).hasClass("blog-list")) {
  setTimeout(() => {
    var elementBottom =
      jQuery(".inner")[0].getBoundingClientRect().bottom + window.scrollY;
    var docHeight = jQuery(document).height();

    console.log("height:", docHeight);
    console.log("bottom:", elementBottom);
    var spacing = docHeight - elementBottom;

    console.log("diff:", spacing);
    (jQuery(".sidebar") as any).sticky({
      topSpacing: 20,
      bottomSpacing: spacing + 50,
    });
    (jQuery(".sidebar") as any).sticky("update");

    jQuery(".sidebar").on("sticky-bottom-reached", function () {
      console.log("Bottom reached");
    });
    jQuery(".sidebar").on("sticky-bottom-unreached", function () {
      console.log("Bottom unreached");
    });
  }, 1000);
}

const LazyLoad = require('vanilla-lazyload/dist/lazyload.js');

var myLazyLoad = new LazyLoad({
  threshold: 600,
});

var myLazyLoadDiv = new LazyLoad({
  elements_selector: '.lazy',
  threshold: 600,
});

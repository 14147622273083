﻿const wnd = window as any;

import * as jQuery from "jquery";
import { AudioProApi, RdvApi, StatsApi } from "../../generated/api";
import { fromPairs } from "../../utils/js-utils";
import { isMobile } from "../../utils/mobile-detector";
import { formatPhoneNumber } from "../global/phone-number-formatter";
export function initRdv() {
  if (wnd.page === "audiopro-detail" || wnd.page === "search-results") {
    $(".js-rdv-url-link").on("click", function (event) {
      const form = $(this).parents("form");
      form.find("button[type=submit]").click();

      if (!(form[0] as HTMLFormElement).checkValidity()) {
        event.preventDefault();
      }
    });

    $(".ask-for-rdv").on("click", function () {
      window["ga"]("send", {
        hitType: "event",
        eventCategory: "Fiche Centre",
        eventAction: "click",
        eventLabel: "Demande de RDV",
      });

      new StatsApi().saveStat({
        query: {
          centerId: $(this).data("audioproid"),
          location: "detail",
          type: $(this).data("stat-type") || "click-rdv",
        },
      });
    });

    $(".rdv-link").on("click", function () {
      window["ga"]("send", {
        hitType: "event",
        eventCategory: "Fiche Centre",
        eventAction: "click",
        eventLabel: "Demande de RDV",
      });
      const audioProId = $(this).data("audioproid") || wnd.audiopro._id;
      new RdvApi().clickedOnRdvLink({
        audioProId: audioProId,
        isMobile: isMobile(),
      });
    });

    $(".rdv-link-callcenter").on("click", function (event) {
      event.preventDefault();
      const id = $(this).data("audioproid");

      ($("#rdvLinkModal-" + id) as any).modal({
        backdrop: true,
      });

      if ((window as any).page === "search-results") {
        $("[name=isSearch]").val("true");
      }
    });

    jQuery(".rdvform").on("submit", function (event) {
      const audioProId = $(this).data("audioproid");
      event.preventDefault();
      event.stopPropagation();
      const $this = $(this);
      var formValues = fromPairs(
        $this.serializeArray().map((i) => [i.name, i.value])
      ) as any;

      console.log(formValues);

      formValues.isMobile = isMobile();

      $this.find(".ask-for-rdv-loader").show();
      $this.find(".ask-for-rdv").hide();
      new RdvApi()
        .askForRdv({ audioProId: audioProId, query: formValues })
        .then(() => {
          console.log("OK!");
        });

      $this.find(".rdv-success").show();
      $this.find(".ask-for-rdv-loader").hide();
      $this.find("#close").show();
      $this.find(".rdv-inputs").hide();

      return false;
    });
  }
}

export function initAudioProDetail() {
  if (wnd.page === "audiopro-detail" && jQuery(".rdv-block").length > 0) {
    initializeReviewsCarousel();
    $("#go-to-rdv-form").on("click", (event) => {
      event.preventDefault();
      $(".rdv-block").click();

      ($ as any).smoothScroll({
        speed: 400,
        scrollTarget: ".rdv-block",
        offset: -50,
      });
    });

    new StatsApi().saveStat({
      query: {
        centerId: window["audiopro"]._id,
        location: "detail",
        type: "view-detail",
      },
    });
  }

  if (wnd.page === "audiopro-detail") {
    if (
      window["audiopro"].popupFreeTestEnabled &&
      window["audiopro"].popupFreeTestDelay > 0
    ) {
      setTimeout(() => {
        const isModalAlreadyOpen = document.querySelector('.modal.show');
        if(isModalAlreadyOpen){
          console.log('Modal already open, exiting.');
          return;
        }

        const identifier = "#automaticFreeTestPopup-" + window["audiopro"]._id;
        console.log('Identifier', identifier);
        ($(identifier) as any).modal({
          backdrop: true,
        });
      }, 1000 * window["audiopro"].popupFreeTestDelay);
    }

    jQuery(".phone-button,.callback-button").on(
      "click",
      async function (event) {
        var id = $(this).data("audioproid");
        var phoneNumberResponse = await new AudioProApi().getPhoneNumber({
          audioProId: id,
        });
        var phoneNumber = formatPhoneNumber(phoneNumberResponse.phoneNumber);
        var enseigne = $(this).data("enseigne");
        var city = $(this).data("city");
        var postalcode = $(this).data("postalcode");
        var address = $(this).data("address");
        var isCallback = $(this).is(".callback-button");
        console.log("isCallback", isCallback);

        var modalId =
          $(this).data("type") === "orl"
            ? "#orlPhoneNumberModal"
            : isCallback
            ? "#callback-" + id
            : "#phoneNumberModal-" + id;
        console.log("modalId", modalId);

        jQuery(modalId + " .modal-phone-number").text(phoneNumber);
        if (phoneNumber.substring(0, 3) === "0 8") {
          jQuery(modalId + " .modal-free-call").text("(Appel Gratuit)");
        } else if (enseigne !== "Audition Santé") {
          jQuery(modalId + " .modal-free-call").text("(Appel non surtaxé)");
        }
        jQuery(modalId + " .modal-enseigne").text(enseigne);
        jQuery(modalId + " .modal-city").text(city);
        jQuery(modalId + " .modal-postalcode").text(postalcode);
        jQuery(modalId + " .modal-address").text(address);

        if ($(this).attr("href") === "#") {
          event.preventDefault();

          // Only on non mobiles devices
          if ($(document).width() > 576 || isCallback) {
            ($(modalId) as any).modal({
              backdrop: true,
            });
          } else {
            var $el = $(this).children(".telephone");
            if ($el.length) {
              if ($el.data("type") === "block") {
                $(this).replaceWith($el.show().css("display", "block"));
              } else {
                $(this).replaceWith($el.show().css("display", "inline-block"));
              }
            }
          }
        }
        window["ga"]("send", {
          hitType: "event",
          eventCategory: "Fiche Centre",
          eventAction: "click",
          eventLabel: "Telephone",
        });
        new StatsApi().saveStat({
          query: {
            centerId: window["audiopro"]._id,
            location: "detail",
            type: "click-phone-number",
          },
        });
      }
    );

    if (document.getElementById("map")) {
      var myLatLng = {
        lat: wnd.audiopro.location.coordinates[1],
        lng: wnd.audiopro.location.coordinates[0],
      };

      var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 17,
        center: myLatLng,
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        title: wnd.audiopro.enseigne,
      });
      marker.setIcon({ url: "/assets/images/pin-blue.png" });
    }
  }

  const scrollDiv = document.createElement("div");
  scrollDiv.className = "modal-scrollbar-measure";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  // Topbar is disabled for now
  /* 
  // Compensate the navbar
  $(".phoneNumberModal").on("show.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", scrollbarWidth);
  });

  $(".phoneNumberModal").on("hidden.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", 0);
  });
  // Compensate the navbar
  $("#orlPhoneNumberModal").on("show.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", scrollbarWidth);
  });

  $("#orlPhoneNumberModal").on("hidden.bs.modal", function (e) {
    $(".center-topbar").css("padding-right", 0);
  });

  function showHideTopBar() {
    const pos = $(document).scrollTop();
    $(".center-topbar").toggleClass("visible", pos > 350);
  }

  // Only on non mobiles devices
  if ($(document).width() > 576) {
    $(window).scroll(showHideTopBar);
    showHideTopBar();
  } */

  if ($("#collapsed-content")[0]) {
    const contentHeight = $("#collapsed-content")[0].scrollHeight;
    if (contentHeight >= 40) {
      $("#see-more").show();
      $("#see-more").on("click", function (event) {
        event.preventDefault();
        $("#collapsed-content").css("max-height", "inherit");
        $("#collapsed-content").css("overflow", "visible");
        $("#see-more").hide();
      });
    }
  }
}

async function initializeReviewsCarousel() {
  ($(".carousel") as any).carousel({ interval: false });

  $(".js-expand-review").on("click", function () {
    $(this)
      .parent(".review-content")
      .hide()
      .siblings(".review-content-full")
      .show();
  });
}
